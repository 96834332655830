import React, {useState} from "react"
import "./Inputs.css"
import {useTranslation} from "react-i18next"
import FormikInput from "./FormikInput"
import {Checkbox} from "antd"

interface CustomProps {
	buttons: any,
	onChange: (value:string) => void,
	selected: string,
	handleInputChange?: (name: string, value:string) => void,
	inputName?: string,
	value?: string,
	errors: any,
	touched: any,
	onBlur: any,
	multiCountrySelect?: boolean,
	setMultiCountrySelect?: (val:boolean) => void
}

export default function RadioGroup(props:CustomProps) {
	const { t } = useTranslation()

	const handleRadioChange = (value:string) => {
		props.onChange(value)
		if (props.inputName && props.handleInputChange) {
			props.handleInputChange(props.inputName, "")
		}
	}

	const handleInput = (value:string) => {
		if (props.inputName && props.handleInputChange) {
			props.handleInputChange(props.inputName, value)
		}
	}

	return(
		<div className="radio_group">
			{props.buttons.map((button:any) => (
				<div
					className="radio_container"
					key={button.value}>
					<div className="row radio_text">
						<input
							onChange={(e:any) => handleRadioChange(e.target.value)}
							type="radio"
							value={button.value}
							name={button.groupName}
							checked={props.selected === button.value}
							onBlur={props.onBlur}
						/> {t(`${button.label}`)}
						{button.multiCountry && props.selected === button.value
							? <div style={{
								marginLeft: "15px"
							}}>
								<Checkbox
									value={props.multiCountrySelect}
									onChange={() => {
										if (props.setMultiCountrySelect) {
											props.setMultiCountrySelect(!props.multiCountrySelect)
										}
									}}
								>
									{t(`${button.countriesTitle}`)}
								</Checkbox>
							</div>
							: null
						}
					</div>
					{button.description
						?
						<p className="radio_description">
							{t(`${button.description}`)}
						</p>
						: null
					}
					{button.input && props.selected === button.value
						?
						<div className="row">
							<FormikInput
								onBlur={props.onBlur(props.inputName || "")}
								errors={props.errors}
								touched={props.touched}
								htmlFor={button.value}
								name={button.value}
								value={props.value || ""}
								disabled={false}
								handleChange={(e:any) => handleInput(e.target.value)}
							/>
						</div>
						: null}
				</div>
			))}
		</div>
	)
}
